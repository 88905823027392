<template>
  <div>
    <div class="text-center header-container">
      <v-container class="py-10">
        <v-row justify="center">
          <v-col class="col-md-6">
            <h1 @click="editar('contact.title')" class="text-header-title white--text" v-html="$t('contact.title')">
            </h1>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div class="about-us__background-container" id="contact">
      <v-container>
        <v-row>
          <v-col class="col-12 col-md-4">
            <h2 @click="editar('contact.section_one')" class="color-about-us">
              {{$t('contact.section_one')}}
            </h2>
            <p @click="editar('contact.so_details')" class="mt-8 about-us__text" v-html="$t('contact.so_details')">
            </p>
          </v-col>
          <v-col class="col-12 col-md-4">
            <img
              width="290"
              height="290"
              class="about-us__image-phone d-block mx-auto"
              alt=""
              :src="$t('contact.ff_name') === 'Name' ? get_image.img_0: get_image.img_1"
            />
            <div class="py-4 pl-4">
              <div class="d-flex align-center">
                <a href="tel:8673343939" class="call-to">
                  <v-icon color="#E30707">mdi-phone</v-icon>
                  <p class="mb-0 ml-4 dark-text">(867) 3343939</p>
                </a>
              </div>

              <div class="d-flex align-center mt-4">
                <v-icon color="#E30707">mdi-whatsapp</v-icon>
                <p class="mb-0 ml-4">+1 (956) 602-5372</p>
              </div>
              <div>
                <p class="mb-0 ml-10">+1 (559) 718-6564</p>
              </div>
            </div>
          </v-col>
          <v-col class="col-12 col-md-4">
            <v-form @submit.prevent="sendContactUsEmail">
              <div>
                <p @click="editar('contact.ff_name')" class="mb-1">{{$t('contact.ff_name')}} <span>*</span></p>
                <v-text-field
                  color="#00126a"
                  outlined
                  dense
                  :error-messages="contactUsFormName"
                  @input="$v.contactUsForm.name.$touch()"
                  @blur="$v.contactUsForm.name.$touch()"
                  v-model="contactUsForm.name"
                />
              </div>
              <div>
                <p @click="editar('contact.ff_name_company')" class="mb-1">{{$t('contact.ff_name_company')}}</p>
                <v-text-field
                  color="#00126a"
                  outlined
                  dense
                  v-model="contactUsForm.company"
                />
              </div>
              <div>
                <p @click="editar('contact.ff_email')" class="mb-1">{{$t('contact.ff_email')}} <span>*</span></p>
                <v-text-field
                  color="#00126a"
                  outlined
                  dense
                  :error-messages="contactUsEmail"
                  @input="$v.contactUsForm.email.$touch()"
                  @blur="$v.contactUsForm.email.$touch()"
                  v-model="contactUsForm.email"
                />
              </div>
              <div>
                <p @click="editar('contact.ff_phone')" class="mb-1">{{$t('contact.ff_phone')}} <span>*</span></p>
                <v-text-field
                  color="#00126a"
                  outlined
                  dense
                  :error-messages="contactUsTelephone"
                  @input="$v.contactUsForm.telephone.$touch()"
                  @blur="$v.contactUsForm.telephone.$touch()"
                  v-model="contactUsForm.telephone"
                />
              </div>
              <div>
                <p @click="editar('contact.ff_equip')" class="mb-1">{{$t('contact.ff_equip')}}</p>
                <v-text-field
                  color="#00126a"
                  outlined
                  dense
                  v-model="contactUsForm.equipment"
                />
              </div>
              <div>
                <p @click="editar('contact.ff_source')" class="mb-1">{{$t('contact.ff_source')}}</p>
                <v-text-field
                  color="#00126a"
                  outlined
                  dense
                  v-model="contactUsForm.source"
                />
              </div>
              <div>
                <p @click="editar('contact.ff_destination')" class="mb-1">{{$t('contact.ff_destination')}}</p>
                <v-text-field
                  color="#00126a"
                  outlined
                  dense
                  v-model="contactUsForm.destination"
                />
              </div>
              <div>
                <p @click="editar('contact.ff_comments')" class="mb-1">{{$t('contact.ff_comments')}}</p>
                <v-textarea
                  color="#00126a"
                  outlined
                  dense
                  v-model="contactUsForm.comments"
                />
              </div>
              <privacy :textAcepto="$t('contact.ff_privacy')" :textPrivacy="$t('contact.ff_privacy_link')" v-model="check1" class="py-0 my-0" :check="true" />
              <div class="mt-4">
                <vue-recaptcha @verify="setContactUsVerified" size="100" ref="recaptcha" :sitekey="robot"/>
              </div>
              <div class="d-flex justify-end mt-6">
                <v-btn
                  color="#E30707"
                  dark
                  depressed
                  type="submit"
                  :loading="isLoadingContact"
                  >{{$t('contact.ff_btn')}}</v-btn
                >
              </div>
            </v-form>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <v-container class="about-us__padding-container">
      <v-row>
        <v-col class="col-12 col-md-4">
          <h2 @click="editar('contact.section_two')" class="color-about-us">
            {{$t('contact.section_two')}}
          </h2>
          <p @click="editar('contact.st_details')" class="mt-10 about-us__text">
            {{$t('contact.st_details')}}
          </p>
        </v-col>
        <v-col class="col-12 col-md-8" id="join">
          <div class="d-flex justify-end about-us__background-image">
            <div class="about-us__form_background-color">
              <v-form @submit.prevent="sendJoinUsEmail">
                <div class="about-us__form-container">
                  <div>
                    <p @click="editar('contact.sf_name')" class="mb-1 white--text">{{$t('contact.sf_name')}}</p>
                    <v-text-field
                    background-color="white"
                      dense
                      outlined
                      v-model="joinUsForm.name"
                    />
                  </div>
                  <div>
                    <p @click="editar('contact.sf_phone')" class="mb-1 white--text">{{$t('contact.sf_phone')}} <span>*</span></p>
                    <v-text-field
                      dense
                      outlined
                      background-color="white"
                      v-model="joinUsForm.telephone"
                      :error-messages="joinUsTelephone"
                      @input="$v.joinUsForm.telephone.$touch()"
                      @blur="$v.joinUsForm.telephone.$touch()"
                    />
                  </div>
                  <div>
                    <p @click="editar('contact.sf_email')" class="mb-1 white--text">{{$t('contact.sf_email')}} <span>*</span></p>
                    <v-text-field
                      background-color="white"
                      outlined
                      dense
                      v-model="joinUsForm.email"
                      :error-messages="joinUsEmail"
                      @input="$v.joinUsForm.email.$touch()"
                      @blur="$v.joinUsForm.email.$touch()"
                    />
                  </div>
                  <div>
                    <p @click="editar('contact.sf_company')" class="mb-1 white--text">{{$t('contact.sf_company')}}</p>
                    <v-text-field
                      background-color="white"
                      outlined
                      dense
                      v-model="joinUsForm.company"
                    />
                  </div>
                  <div>
                    <p @click="editar('contact.sf_message')" class="mb-1 white--text">{{$t('contact.sf_message')}}</p>
                    <v-textarea
                      background-color="white"
                      color="#00126a"
                      outlined
                      dense
                      v-model="joinUsForm.message"
                    />
                  </div>
                  <privacy
                    v-model="check2"
                    :textAcepto="$t('contact.sf_privacy')"
                    :textPrivacy="$t('contact.sf_privacy_link')"
                    class="py-0 my-0"
                    :check="true"
                    color="white"
                  />
                  <div class="mt-4">
                    <vue-recaptcha @verify="setJoinUsVerified" size="100" ref="recaptcha" :sitekey="robot"/>
                  </div>
                  <div class="d-flex justify-end mt-6">
                    <v-btn
                      color="#E30707"
                      type="submit"
                      dark
                      :disabled="!check2 || isJoinUsLock"
                      depressed
                      :loading="isLoadingJoinUs"
                      >{{$t('contact.ff_btn')}}</v-btn
                    >
                  </div>
                </div>
              </v-form>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="showDialog" max-width="300">
      <v-card>
        <v-card-title @click="editar('contact.e_title')">{{$t('contact.e_title')}}</v-card-title>
        <v-card-text @click="editar('contact.e_subtitle')">{{$t('contact.e_subtitle')}}</v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="#071C87" dark depressed @click="closeDialog">{{$t('contact.e_ok')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { VueRecaptcha } from 'vue-recaptcha';
import { validationMixin } from 'vuelidate'
import { required, email, minLength } from 'vuelidate/lib/validators'
import Privacy from '@/components/Privacy.vue'
import axios from 'axios';
import { CONTACT_US } from '@/assets/img/images';

export default {
  nuxtI18n: {
    paths: {
      en: '/contact-us',
      es: '/contactanos',
    },
  },
  metaInfo() {
    return {
      title: this.$t('contact.seo.title'),
      meta: [
        { name: 'descripción', content: this.$t('contact.seo.description') },
        { name: 'keywords', content: this.$t('contact.seo.keywords') },
      ],
    }
  },
  components: {
    Privacy,
    VueRecaptcha
  },
  mixins: [validationMixin],
  nuxtI18n: {
    paths: {
      en: '/contact-us',
      es: '/contactanos',
    },
  },
  mounted() {
    setTimeout(() => {
      if (this.$route.query.section) {
        window.scrollTo({
          top: document.getElementById(this.$route.query.section).offsetTop,
          behavior: 'smooth',
        })
      }
    }, 1000)
  },
  validations: {
    contactUsForm: {
      name: { required },
      email: { required, email },
      telephone: { required, minLength: minLength(10) },
    },
    joinUsForm: {
      email: { required, email },
      telephone: { required, minLength: minLength(10) },
    },
  },
  data: () => ({
    check1: false,
    check2: false,
    isContactLock: true,
    isJoinUsLock: true,
    isLoadingContact: false,
    isLoadingJoinUs: false,
    showDialog: false,
    contactUsForm: {
      type: 0,
      name: '',
      company: '',
      email: '',
      telephone: '',
      equipment: '',
      source: '',
      destination: '',
      comments: '',
    },
    joinUsForm: {
      type: 1,
      name: '',
      telephone: '',
      email: '',
      company: '',
      message: '',
    },
  }),
  methods: {
    sendEmailToContact(form) {
      return axios
        .post(`${process.env.VUE_APP_API}/email-contact-us`, form)
        .finally(() => (this.showDialog = true));
    },
    sendJoinUsEmail() {
      this.$v.joinUsForm.$touch()
      if (!this.$v.joinUsForm.$invalid) {
        this.isLoadingJoinUs = true
        this.sendEmailToContact(this.joinUsForm).finally(
          () => (this.isLoadingJoinUs = false)
        )
      }
    },
    sendContactUsEmail() {
      this.$v.contactUsForm.$touch()
      if (!this.$v.contactUsForm.$invalid) {
        this.isLoadingContact = true
        this.sendEmailToContact(this.contactUsForm).finally(
          () => (this.isLoadingContact = false)
        )
      }
    },
    closeDialog() {
      this.showDialog = false
    },
    setContactUsVerified() {
      this.isContactLock = false
    },
    setJoinUsVerified(dato) {
      this.isJoinUsLock = false
    },
  },
  computed: {
    robot(){
      return process.env.VUE_APP_ROBOT;
    },
    get_image(){
      return CONTACT_US
    },
    contactUsFormName() {
      const errors = []
      if (!this.$v.contactUsForm.name.$dirty) return errors
      !this.$v.contactUsForm.name.required && errors.push('Name is required.')
      return errors
    },
    contactUsEmail() {
      const errors = []
      if (!this.$v.contactUsForm.email.$dirty) return errors
      !this.$v.contactUsForm.email.email && errors.push('Must be valid e-mail')
      !this.$v.contactUsForm.email.required && errors.push('E-mail is required')
      return errors
    },
    contactUsTelephone() {
      const errors = []
      if (!this.$v.contactUsForm.telephone.$dirty) return errors
      !this.$v.contactUsForm.telephone.minLength &&
        errors.push('Telephone must be at min 10 characters long')
      !this.$v.contactUsForm.telephone.required &&
        errors.push('Telephone is required.')
      return errors
    },
    joinUsEmail() {
      const errors = []
      if (!this.$v.joinUsForm.email.$dirty) return errors
      !this.$v.joinUsForm.email.email && errors.push('Must be valid e-mail')
      !this.$v.joinUsForm.email.required && errors.push('E-mail is required')
      return errors
    },
    joinUsTelephone() {
      const errors = []
      if (!this.$v.joinUsForm.telephone.$dirty) return errors
      !this.$v.joinUsForm.telephone.minLength &&
        errors.push('Telephone must be at min 10 characters long')
      !this.$v.joinUsForm.telephone.required &&
        errors.push('Telephone is required.')
      return errors
    },
  },
}
</script>

<style scoped lang="scss">
.dark-text {
  color: #181818;
}

.color-about-us {
  color: #000;
}

.header-container {
  background-color: #000;
}

.text-header-title {
  font-size: 75px;
}

.about-us__text {
  line-height: 32px;
}

.about-us__background-container {
  padding: 48px 0;
  background-color: #f4f4f4;
}

.about-us__padding-container {
  padding-top: 64px;
  padding-bottom: 64px;
}

.about-us__form_background-color {
  background-color: #000;
  width: 60%;
  border-radius: 8px;
}

.about-us__form-container {
  padding: 24px;
}

.about-us__background-image {
  background-image: url('https://res.cloudinary.com/omni-international-logistics/image/upload/v1642133046/Img%20Omni%20WEB/Pag%20CONTACT%20US-%20contactanos/CARRIER_professional-truck-driver-entering-his-truck-long-vehicle-and-holding-thumbs-up_1_uni6ra.png');
  background-position-x: -50%;
  background-size: 60% 100%;
}

.about-us__image-phone {
  object-fit: cover;
}

// ::v-deep .v-input__control > .v-input__slot,
// .v-text-field.v-text-field--enclosed .v-text-field__details {
//   background-color: white;
// }

@media screen and (max-width: 768px) {
  .about-us__form_background-color {
    width: 100%;
  }

  .text-header-title {
    font-size: 36px;
  }

  .about-us__form-container {
    padding: 24px 10px;
  }

  .about-us__form_background-color {
    border-radius: 0;
  }

  #join {
    padding: 0;
  }
}
</style>
